<template>
  <div>
    <b-card>
      <contracts-table
        :items="contracts"
        :loading="loading"
        show-button
        show-search
      />
    </b-card>
  </div>
</template>

<script>
import ContractsTable from "@/views/contracts/list/components/ContractsTable.vue";
import {
  getBillingTypeName,
  getContractStatusColor,
  getContractStatusName,
  notifyError,
} from "@/utils/methods";
import { BCard } from "bootstrap-vue";
import {
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";

export default {
  name: "ContractsList",
  components: {
    BCard,
    ContractsTable,
  },
  computed: {
    loading() {
      return this.$store.getters["contracts/loading"];
    },
    contracts() {
      return this.$store.getters["contracts/contracts"].map((item) => ({
        uuid: item.uuid || this.$t("No definit"),
        sortStartDate:
          formatDateStringToDatabaseDate(item.startDate) ||
          this.$t("No definit"),
        startDate:
          formatDateStringToDate(item.startDate) || this.$t("No disponible"),
        sortEndDate:
          formatDateStringToDatabaseDate(item.endDate) || this.$t("No definit"),
        endDate:
          formatDateStringToDate(item.endDate) || this.$t("No disponible"),
        accommodation: item.accommodation?.name || this.$t("No definit"),
        accommodationUuid: item.accommodation?.uuid || null,
        owner: item.owner?.fullName || this.$t("No definit"),
        coOwners: this.getCoOwnersNames(item),
        status: item.status || null,
        statusName: getContractStatusName(item.status) || this.$t("No definit"),
        statusColor:
          getContractStatusColor(item.status) || this.$t("No definit"),
        billingType:
          getBillingTypeName(item.billingType) || this.$t("No definit"),
      }));
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store
        .dispatch("contracts/fetchContracts", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchContracts.title"),
            this.$t("errors.fetchContracts.description")
          )
        );
    },
    getCoOwnersNames(contract) {
      if (!contract?.coOwners) return [];
      return contract.coOwners.map((coOwner) => coOwner.fullName || null);
    },
  },
};
</script>
