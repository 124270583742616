<template>
  <div class="contracts-table">
    <!-- NEW BUTTON AND SEARCH -->
    <b-row
      v-if="showSearch || showButton"
      class="mb-2 d-flex flex-wrap"
      :class="{
        'justify-content-between': showButton,
        'justify-content-end': !showButton,
      }"
    >
      <b-col
        cols="3"
        md="auto"
        class="mb-1 mb-md-0 d-flex justify-content-start align-items-center"
      >
        <b-button
          v-if="showButton"
          variant="primary"
          block
          @click.prevent="newItemButtonClicked"
          class="mr-1"
        >
          <feather-icon icon="PlusIcon" class="d-block d-md-none" />
          <span class="d-none d-md-inline">{{ $t("Nou contracte") }}</span>
        </b-button>
        <b-form-checkbox class="d-flex" v-model="showTerminated">
          <span class="text-nowrap"> Mostrar finalitzats </span>
        </b-form-checkbox>
      </b-col>
      <b-col
        v-if="showSearch"
        cols="9"
        md="auto"
        class="mb-1 mb-md-0"
        :class="{
          'justify-content-between': showButton,
          'justify-content-end': !showButton,
        }"
      >
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          hover
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="filteredItems"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
          @row-clicked="onRowClicked"
          @row-contextmenu="onRowRightClicked"
          @row-middle-clicked="onRowMiddleClicked"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: start date -->
          <template #cell(sortStartDate)="data">
            {{ data.item.startDate }}
          </template>

          <!-- Column: end date -->
          <template #cell(sortEndDate)="data">
            {{ data.item.endDate }}
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="data">
            <b-accommodation
              :uuid="data.item.accommodationUuid"
              :name="data.value"
            />
          </template>

          <!-- Column: owner -->
          <template #cell(owner)="data">
            <span>{{ data.value }}</span>
            <div
              v-for="(coOwner, coOwnerIndex) in data.item.coOwners"
              :key="`${data.item.uuid}-coOwner-${coOwnerIndex}`"
            >
              <small>{{ coOwner }}</small>
            </div>
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge pill :variant="data.item.statusColor">
              {{ data.item.statusName }}
            </b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="totalRows > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  BSpinner,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";

export default {
  name: "ContractsTable",
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BSpinner,
    BBadge,
    BAccommodation,
    BFormCheckbox,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showButton: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "sortStartDate",
      sortDesc: true,
      perPage: 10,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      showTerminated: false,
      fields: [
        {
          key: "sortStartDate",
          label: this.$t("Data d'inici"),
          sortable: true,
        },
        { key: "sortEndDate", label: this.$t("Data de fi"), sortable: true },
        { key: "accommodation", label: this.$t("Allotjament"), sortable: true },
        { key: "owner", label: this.$t("Propietari"), sortable: true },
        { key: "billingType", label: this.$t("Facturació"), sortable: true },
        { key: "status", label: this.$t("Estat"), sortable: true },
      ],
      selectedItem: null,
    };
  },
  computed: {
    filteredItems() {
      if (!this.items?.length) {
        return [];
      }

      return this.items.filter((contract) => {
        if (this.showTerminated) {
          return true;
        }
        return contract.status !== "TERMINATED";
      });
    },
  },
  watch: {
    items(items) {
      this.totalRows = items.length;
    },
    filteredItems(items) {
      this.totalRows = items.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.filteredItems.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowClicked(item) {
      this.$router.push({
        name: "foravila-contract-view",
        params: {
          contractUuid: item.uuid,
        },
      });
    },
    onRowRightClicked(item) {
      this.selectedItem = item;
      this.openInNewTab();
    },
    onRowMiddleClicked(item) {
      this.selectedItem = item;
      this.openInNewTab();
    },
    openInNewTab() {
      const routeData = this.$router.resolve({
        name: "foravila-contract-view",
        params: {
          contractUuid: this.selectedItem.uuid,
        },
      });
      window.open(routeData.href, "_blank");
    },
    newItemButtonClicked() {
      this.$router.push({
        name: "foravila-new-contract",
      });
    },
  },
};
</script>

<style lang="scss">
.contracts-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
